import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js';

Vue.config.productionTip = false
Vue.prototype.$EventBus = new Vue();

router.afterEach((to) => {
  if(to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '投手-您身边的服务专家'
  }
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
