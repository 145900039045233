<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
			return {
				
			}
		},
    created(){

    },
  methods: {
    
  },
};
</script>

<style>
html,body,#app{
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  word-break: break-all;
}
</style>
