import Router from 'vue-router';
import Vue from 'vue';
Vue.use(Router);
const routes = [
  {
    path: '/',
    meta: {
      title: '投手-您身边的服务专家',
    },
    redirect: '/shareTrace',
  },
  {
    path: '/shareTrace',
    name: 'ShareTrace',
    meta: {
      title: '投手app下载'
    },
    component: r => require.ensure([], () => r(require('views/shareTrace/index.vue')))
  },
  {
    path: '/privacyAgreement',
    name: 'PrivacyAgreement',
    meta: {
      title: '投手隐私政策'
    },
    component: r => require.ensure([], () => r(require('views/agreements/privacy.vue')))
  },
  {
    path: '/serviceAgreement',
    name: 'ServiceAgreement',
    meta: {
      title: '投手用户服务协议'
    },
    component: r => require.ensure([], () => r(require('views/agreements/service.vue')))
  },
  {
    path: '/makeMoney',
    name: 'MakeMoney',
    meta: {
      title: '分享赚钱'
    },
    component:r => require.ensure([], () => r(require('views/brandIntroduction/index.vue'))) 
  },
  {
    path: '/use',
    name: 'Use',
    meta: {
      title: '如何使用'
    },
    component:r => require.ensure([], () => r(require('views/brandIntroduction/index.vue'))) 
  },
  {
    path: '/brandIntroduction',
    name: 'BrandIntroduction',
    meta: {
      title: '品牌介绍'
    },
    component:r => require.ensure([], () => r(require('views/brandIntroduction/index.vue'))) 
  },
  {
    path: '/authSuccess',
    name: 'AuthSuccess',
    meta: {
      title: '处理中'
    },
    component:r => require.ensure([], () => r(require('views/authSuccess/index.vue'))) 
  },
  {
    path: '/userInfo',
    name: 'UserInfo',
    meta: {
      title: '客户信息'
    },
    component:r => require.ensure([], () => r(require('views/userInfo/index.vue'))) 
  }

]


export default new Router({
  mode: 'history',
  routes,
  scrollBehavior () {
    return {x:0 ,y: 0};
  }
})